.FeaturedCardSec {
  margin-top: 40px;
  display: flex;

  .FeatCard {
    padding: 10px;
    width: 100%;
  }

  .FeaturedCardSection {
    .XmaxCard {
      display: block;
      margin-bottom: 50px;

      .XmasImage {
        .ShowDate {
          background-color: #ff2851;
        }
      }

      .XmasDetails {
        text-align: center;
        padding: 20px 0;

        .PlaceName {
          min-height: 80px;

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .EveHeading {
          min-height: 60px;

          h3 {
            font-weight: bold;
          }
        }

        .PlaceName {
          button {
            font-size: 13px;
            line-height: 15px;
            border: 1px solid #fff;
            outline: 3px solid #4cb2dc;
            text-transform: uppercase;
            background-color: #4cb2dc;
            padding: 6px 10px;
            margin: 5px 10px;
            color: #fff;
            font-weight: 700;
            font-family: "Lato";
          }

          p {
            color: #ff2851;
            font-size: 12px;
            font-family: "Lato";
            font-weight: 600;
            text-transform: uppercase;
            padding: 0 10px;
          }
        }

        .PartyHeading {
          padding-bottom: 20px;
        }
      }

      .cardFooter {
        bottom: auto;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  .FeaturedCardSec {
    .XmaxCard {
      .XmasImage {
        padding: 0;
      }

      margin-bottom: 30px;

      .XmasDetails {
        padding-bottom: 0 !important;
        margin-bottom: 0px;

        .EveHeading {
          min-height: unset !important;
        }

        .cardFooter {
          padding: 0;
          left: 0;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 767px) and (max-width: 992px) {
  .XmaxCard .XmasMin {
    padding-left: 0 !important;
  }
  .XmasDetails {
    .PlaceName {
      min-height: 115px !important;
    }
    .EveHeading h3 {
      font-size: 18px;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .XmaxCard .XmasDetails {
    width: 100% !important;
    padding-top: 10px !important;

    .partyContent {
      margin-top: 5px !important;

      p {
        margin: 0 !important;
      }
    }

    .PlaceName {
      // min-height: 140px !important;

      button {
        width: 80px !important;
        font-size: 10px !important;
        margin: 5px 5px !important;
        padding: 6px 0px !important;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
  .FirstCard {
    .XmaxCard .XmasDetails {
      padding-top: 20px !important;

      .partyContent {
        margin-top: 0 !important;

        .CardSub-title {
          font-size: 11px;
        }
      }
    }
  }
  .PlaceName {
    // min-height: 118px !important;
    button {
      width: 90px !important;
      font-size: 10px !important;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {
}

// Grid Icons
.gridicon1 {
  background-image: url(../../Assets/Images/listgrid.png);
  margin-left: 20px;
  cursor: pointer;
  margin-top: -5px;
  width: 24px;
  height: 20px;
  display: inline-block;
  background-position: 0px 0;
}

.LightGridIcon1 {
  background-image: url(../../Assets/Images/listgrid.png);
  margin-left: 20px;
  cursor: pointer;
  margin-top: -5px;
  width: 24px;
  height: 20px;
  display: inline-block;
  background-position: 0px 20px;
}

.gridicon2 {
  background-image: url(../../Assets/Images/listgrid.png);
  margin-left: 20px;
  cursor: pointer;
  margin-top: -5px;
  width: 24px;
  height: 20px;
  display: inline-block;
  background-position: 24px 0;
}

.LightGridIcon2 {
  background-image: url(../../Assets/Images/listgrid.png);
  margin-left: 20px;
  cursor: pointer;
  margin-top: -5px;
  width: 24px;
  height: 20px;
  display: inline-block;
  background-position: 24px 20px;
}
