.CardSection {
  margin-bottom: 35px;
}
.AddStoreSection.noStoreBanner {
  display: none;
}
.AddStoreSection {
  display: block;
  .AddStoreSec {
    margin-top: 15px;
    margin-bottom: 30px;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.XmaxCard {
  display: flex;
  border: 1px solid #e9e8e8;

  a {
    color: #fff;
    text-decoration: none;
    span {
      font-size: 14px;
    }
  }

  .XmasImage {
    width: 100%;

    .ShowDate {
      position: absolute;
      background-color: #ff2851;
      text-align: center;
      color: #fff;
      font-weight: 700;
      padding: 0px 10px;
      font-size: 16px;
      height: 50px;

      p {
        margin: 0;
      }
    }

    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .XmasDetails {
    padding-left: 25px;
    padding-top: 20px;
    position: relative;
    background-color: #fff;
    width: 100%;
    // padding-right: 20px;
    .SubContent{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a{
        button{
          width: fit-content;
        }
      }
    }

    .EveHeading h5 {
      color: #000;
      font-weight: 700;
      font-size: 22px;
      text-transform: uppercase;
    }

    .partyContent {
      margin: 10px 10px 0 0;
    }

    .PartyHeading {
      margin-top: 20px;

      p {
        cursor: pointer;
      }

      p.CardSub-title {
        font-size: 14px;
        font-family: "Lato";
        font-weight: 700;
        margin-bottom: 8px;
        color: #ff2851;
        text-transform: uppercase;
        cursor: default;
      }

      button {
        outline: 3px solid #4cb2dc;
        background-color: #4cb2dc;
        color: #fff;
        border: 1px solid #fff;
        box-shadow: none;
        font-weight: 600;
        font-size: 13px;
        padding: 6px 14px;
        margin: 0px 4px;

        img {
          padding-right: 3px;
        }
      }

      a {
        color: #4cb2dc;
        text-decoration: underline;
        font-size: 11px;
      }

      p {
        margin: 8px 0;
        font-size: 12px;
      }

      button {
        margin-bottom: 10px;
        width: 110px;
      }
    }

    .cardFooter {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      .PriceSec {
        display: flex;
      }

      button.Price {
        background-color: #4a4a4a;
        color: #fff;
        padding: 8px 0;
        border: 0;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        cursor: auto;
      }

      button.SaleHeading {
        width: 100%;
        background-color: #ff2851;
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        padding: 8px 0;

        .cardFooter {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;

          .PriceSec {
            display: flex;
          }

          button.Price {
            background-color: #4a4a4a;
            color: #fff;
            padding: 8px 0;
            border: 0;
            font-size: 17px;
            font-weight: 600;
            width: 100%;
            cursor: auto;
          }

          button.SaleHeading {
            width: 100%;
            background-color: #ff2851;
            border: 0;
            color: #fff;
            font-size: 15px;
            font-weight: 600;
            text-transform: uppercase;
            width: 100%;

            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  .XmaxCard {
    display: block;

    .XmasImage {
      width: 100%;
    }

    .XmasDetails {
      width: 100%;
      text-align: center;
      padding-left: 0;

      .PartyHeading {
        margin-top: 15px;
      }

      .partyContent {
        min-height: 110px;
      }

      .cardFooter {
        bottom: unset;
        position: relative;
        margin-top: 10px;
        padding: 0;
        left: 0px;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 575px) and (max-width: 768px) {
  .partyContent {
    min-height: 144px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .FirstCard {
    .CardSection {
      .XmaxCard {
        min-height: 230px;
      }
    }
  }

  .CardSection {
    .XmaxCard {
      min-height: 198px;

      .EveHeading {
        h5 {
          min-height: 53px;
        }
      }

      .partyContent {
        .SubContent {
          margin-top: 6px;

          button {
            margin: 3px 0;
          }
        }
      }
    }
  }

  .XmasDetails {
    padding-left: 12px !important;
    padding-top: 5px !important;

    .partyContent {
      margin-top: 0 !important;
    }

    .PartyHeading {
      .SubContent {
        button {
          width: fit-content;
          padding: 4px 5px 4px 9px;
          margin-right: 10px !important;
        }

        .PackageParking {
          display: none;
        }
      }
    }

    .cardFooter {
      button.Price {
        font-size: 12px !important;
      }

      .SaleHeading {
        a {
          font-size: 12px;
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .FirstCard {
    .CardSection {
      .XmaxCard {
        min-height: 260px;
        .XmasDetails {
          position: relative;
          .EveHeading {
            min-height: 55px;
          }
        }
      }
    }
  }
  p.OnlyLaptop {
    display: none;
  }

  .OnlyTab {
    display: none;
  }

  .XmaxCard {
    .XmasDetails {
      .cardFooter button.Price {
        font-size: 15px;
      }

      .partyContent {
        .SubContent {
          margin-top: 10px;

          button {
            margin: 7px 0;
          }
        }
      }

      p.SubPara {
        margin-top: 10px !important;
      }
    }
  }
}

@media (min-width: 1200px) {
  .FirstCard {
    .CardSection {
      .XmaxCard {
        min-height: 260px;
        .XmasDetails {
          position: relative;
          .EveHeading {
            min-height: 55px;
          }
          // .parkingAnchor {
          //   position: absolute;
          //   bottom: 60px;
          // }
          // .CardSub-title {
          //   min-height: 60px;
          // }
        }
      }
    }
  }
}
